import React, { FC, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import { HeaderSection, Footer, CollectionSection } from './components'
import { observer } from 'mobx-react-lite'
import { RootStore } from 'store'

export const Main: FC = observer(() => {
  const searchStore = RootStore.searchStore
  const favouritesStore = RootStore.favouritesStore

  const refContainer = useRef<HTMLDivElement>(null)
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  
  useEffect(() => {
    setScrollbarWidth(window.innerWidth - (refContainer.current?.clientWidth || 0))
  }, [searchStore.isSearchOpen])

  return (
    <Container ref={refContainer}>
      <Mask 
        mask={searchStore.isSearchOpen || favouritesStore.favourites.length > 0} 
        scrollWidth={scrollbarWidth}
      />
      <Wrapper>
        <HeaderSection />
        <CollectionSection />
      </Wrapper>
      <Footer />
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  flex-flow: column;
  overflow: auto;
  height: 100%;
  position: relative;
`

const Mask = styled.div<{mask: boolean, scrollWidth: number}>`
    ${p => p.mask && css`
    &::before {
      content: '';
      background-color: var(--color-background);
      opacity: 0.75;
      inset: 0;
      width: calc(100% - ${p.scrollWidth}px);
      height: 100%;
      position: fixed;
    }
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  flex: 1 0 auto;
`
