import { FC } from 'react'
import { motion } from 'framer-motion'
import s from './Control.module.scss'

const VARIANTS = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
}

interface IControl {
  condition: 'visible' | 'hidden'
  icon: JSX.Element
  onClick: () => void
  disabled: boolean
  className?: string
}

export const Control: FC<IControl> = (props) => (
  <motion.div className={props.className + ' clickable ' + s['container']} animate={props.condition} variants={VARIANTS}>
    <motion.button className={s['control']} whileTap={{ scale: 0.9 }} onClick={props.onClick} disabled={props.disabled}>
      {props.icon}
    </motion.button>
  </motion.div>
)
