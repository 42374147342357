import { useRef, useEffect, useState, RefObject } from 'react'

export const useResizeObserver = (target: RefObject<HTMLElement>) => {
  const [size, setSize] = useState<ResizeObserverEntry['contentRect']>()
  const observer = useRef<ResizeObserver>(new ResizeObserver(([entry]) => setSize(entry.contentRect))).current

  useEffect(() => {
    const node = target.current
    if (!node) return
    observer.observe(node)

    return () => observer.unobserve(node)
  }, [observer, target])

  return { size }
}
