import { useRef, useMemo } from 'react'
import { useResizeObserver } from './useResizeObserver.hook'
import { BreakpointsEnum, IBreakpoints } from 'interfaces'

const BREAKPOINTS_MAP = new Map([
  [BreakpointsEnum.DESKTOP, 1440],
  [BreakpointsEnum.TABLET, 642],
  [BreakpointsEnum.MOBILE, 0],
])

const BREAKPOINTS = [BreakpointsEnum.DESKTOP, BreakpointsEnum.TABLET, BreakpointsEnum.MOBILE]

interface IUseResponsiveBreakpoints {
  breakpoint: IBreakpoints
}

export const useResponsiveBreakpoints = (): IUseResponsiveBreakpoints => {
  const host = useRef(document.getElementById('root'))
  const { size } = useResizeObserver(host)

  const breakpoint = useMemo<BreakpointsEnum>(() => {
    const width = size?.width || host.current!.offsetWidth

    const point = BREAKPOINTS.find(el => {
      if (width >= (BREAKPOINTS_MAP.get(el) as number)) return true
      return false
    })

    return point || BreakpointsEnum.DESKTOP
  }, [size?.width])

  return { breakpoint }
}
