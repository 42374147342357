import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { ShelfItemApi } from 'mgw-api'
import { gap, mediaQueries } from 'styles/global-styles'
import { ReactComponent as Add } from 'assets/icon/item-add.svg'
import { ReactComponent as Picked } from 'assets/icon/item-picked.svg'

interface ISearchItem {
  key: string
  item: ShelfItemApi
  picked: boolean;
  onPick: () => void;
  onDiscard: () => void;
}

export const SearchItem: FC<ISearchItem> = observer(({ key, item, picked, onPick, onDiscard }) => {

  const changeState = () => {
    if (!picked) onPick()
    else onDiscard()
  }

  return (
      <Row key={key} onClick={changeState} picked={picked}>
        <Poster src={item.imageUrl} />
        <Title>{item.title}</Title>
        <ChangeStateButton>
          {picked ? <Picked /> : <Add />}
        </ChangeStateButton>
      </Row>
  )
})

const Row = styled.div<{picked: boolean}>`
  position: relative;
  display: flex;
  align-items: center;
  height: 126px;
  cursor: pointer;

  &:hover {
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.05);
    
    svg > * {
      fill: ${p => p.picked ? '' : 'white'};
    }
  }

  &:last-child {
    margin-bottom: ${gap.common};
  }

  ${mediaQueries.tablet} {
    height: 84px;

    &:last-child {
      margin-bottom: ${gap.mobile};
    }
  }
`

interface IPoster {
  src: string
}

const Poster = styled.div<IPoster>`
  height: inherit;
  aspect-ratio: 90 / 126;
  border-radius: 8px;
  background-image: ${p => `url(${p.src})`};
  background-size: cover;
  margin-right: ${gap.common};

  ${mediaQueries.tablet} {
    margin-right: ${gap.mobile};
  }
`

const Title = styled.div`
  flex: 1;
  font-family: 'MTS Text';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;

  ${mediaQueries.tablet} {
    font-size: 17px;
    line-height: 24px;
  }
`

const ChangeStateButton = styled.div`
  height: inherit;
  padding: 0 19px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaQueries.tablet} {
    padding: 0 14px;
  }
`