import { FC, useState, useRef, useMemo } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { motion, AnimatePresence, Transition } from 'framer-motion'
import { Control } from '../Control'
import { FavouriteElement } from './FavouriteElement'
import { useResizeObserver, useResponsiveBreakpoints } from 'helpers/hooks'
import { BreakpointsEnum, IBreakpoints } from 'interfaces'
import { ReactComponent as ArrowLeftIcon } from 'assets/icon/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from 'assets/icon/arrow-right.svg'
import { BreakPoints } from 'styles/global-styles'
import { RootStore } from 'store'

interface IPosterList {}

const TRANSITION: Transition = {
  when: 'afterChildren',
  ease: 'easeOut',
}

const CARD_SIZES = new Map<IBreakpoints, number>([
  [BreakpointsEnum.DESKTOP, 192],
  [BreakpointsEnum.TABLET, 156],
  [BreakpointsEnum.MOBILE, 140],
])

const CARD_OFFSETS = new Map([
  [BreakpointsEnum.DESKTOP, 32],
  [BreakpointsEnum.TABLET, 32],
  [BreakpointsEnum.MOBILE, 8],
])

export const FavouriteList: FC<IPosterList> = observer(() => {
  const favouritesStore = RootStore.favouritesStore

  const container = useRef<HTMLDivElement>(null)
  const posterList = useRef<HTMLDivElement>(null)
  const { size } = useResizeObserver(container)
  const { breakpoint } = useResponsiveBreakpoints()
  const isMobile = window.innerWidth < 768

  const cardSize = CARD_SIZES.get(breakpoint) as number
  const cardOffset = CARD_OFFSETS.get(breakpoint) as number

  const count = favouritesStore.favourites.length
  const displayCount = Math.floor((size?.width || 1350) / (cardSize + cardOffset))
  // const displayCount = 2

  const condition = count >= displayCount ? 'visible' : 'hidden'

  const [position, setPosition] = useState(0)

  function getMaxPosition(allCount: number, sliceCount: number) {
    const maxPos = Math.floor(allCount / sliceCount)
    const result = Math.max(maxPos, 0)
    return result
  }

  const maxPosition = useMemo(() => {
    return getMaxPosition(count, displayCount)
  }, [count, displayCount])

  const isLastPosition = position === maxPosition

  const isFirstPosition = position === 0
  
  const visibleList = useMemo(() => {
    if (isMobile) return favouritesStore.favourites

    const start = position * displayCount
    const end = start + displayCount
    if (!isFirstPosition && isLastPosition) return favouritesStore.favourites.slice(-(displayCount - 1))
    return favouritesStore.favourites.slice(start, end)
  }, [isMobile, count, position])

  const width = (visibleList.length + 1) * (cardSize + cardOffset) - cardOffset

  const onNext = () => setPosition(v => v + 1)
  const onPrev = () => setPosition(v => v - 1)

  function scrollToEnd() {
    setTimeout(() => {
      if (!posterList.current) return;
      posterList.current.scrollLeft = posterList.current.scrollWidth
    }, 700)
  }

  function onAdd() {
    console.log('onAdd');
    
    scrollToEnd();

    const _maxPos = getMaxPosition(favouritesStore.favourites.length, displayCount)
    if (_maxPos > position) onNext()
  }

  function onRemove() {
    console.log('onRemove');

    const _maxPos = getMaxPosition(favouritesStore.favourites.length, displayCount)
    if (_maxPos < position) onPrev()
  }

  return (
    <Wrapper ref={container}>
      <Container>
        <Control 
          className={isMobile ? 'hidden' : ''} 
          condition={condition} 
          icon={<ArrowLeftIcon />} 
          onClick={onPrev} 
          disabled={isFirstPosition} 
        />

        <PosterList 
          ref={posterList}
          className='scrollable-x-mobile'
          initial={{ width: cardSize }} 
          animate={{ width }} 
          transition={TRANSITION} 
          style={{ height: cardSize * 1.4 + 68, maxWidth: (cardSize+cardOffset)*displayCount }}
        >
          <AnimatePresence>
            {visibleList.map((v, idx) => (
              <FavouriteElement 
                className='favorite-poster'
                key={v.gid} 
                idx={idx}
                id={v.gid}
                imageUrl={v.imageUrl} 
                width={cardSize} 
                offset={cardOffset} 
                onRemove={onRemove}
              />
            ))}

            {isLastPosition && (
              <FavouriteElement 
                addParagraph={count === 0}
                className='favorite-poster'
                key={'placeholder'} 
                idx={visibleList.length} 
                id={'placeholder'}
                imageUrl={'placeholder'} 
                width={cardSize} 
                offset={cardOffset} 
                onAdd={onAdd}
              />
            )}
          </AnimatePresence>
        </PosterList>
        <Control 
          className={isMobile ? 'hidden' : ''} 
          condition={condition} 
          icon={<ArrowRightIcon />} 
          onClick={onNext} 
          disabled={isLastPosition} 
        />
      </Container>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 2rem 1fr 2rem;
  grid-auto-columns: max-content;
  justify-content: flex-end;
  grid-gap: 1.5rem;
  max-width: inherit;

  @media screen and (max-width: ${BreakPoints.tablet}) {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  /* .favorite-poster {
    &:first-of-type {
      margin-left: 16px;
    }

    &:last-of-type {
      margin-right: 16px;
    }
  } */
`

const PosterList = styled(motion.div)`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-content: flex-end;
  grid-gap: 2rem;
  max-width: 100%;
  height: 336px;
  position: relative;
  border-radius: 15px;

  @media screen and (max-width: ${BreakPoints.tablet}) {
    max-width: 100vw;
  }
`
