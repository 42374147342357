import { makeAutoObservable } from 'mobx'
import { SwiperRef } from 'swiper/react';

export class SliderStore {
  public refSlider: SwiperRef | null = null
  public nextIdx = 0
  public focusedSlideIdx = 0;

  constructor() {
    makeAutoObservable(this)
  }
}