import { FC, useEffect } from 'react'
import { Heading, Paragraph } from '@components'
import { FavouriteList } from './components'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { ReactComponent as BackIcon } from 'assets/icon/back.svg'
import s from './HeaderSection.module.scss'
import { observer } from 'mobx-react-lite'
import { RootStore } from 'store'
import { SearchBar } from '@components/Search/SearchBar'
import { SearchDropDown } from '@components/Search/SearchDropDown'
import classNames from 'classnames'

export const HeaderSection: FC = observer(() => {
  const searchStore = RootStore.searchStore
  const favouritesStore = RootStore.favouritesStore

  const { isSearchOpen, closeSearch, fetchPopular } = searchStore
  const { favourites } = favouritesStore

  useEffect(() => {
    fetchPopular()
  }, [])

  return (
    <div className={s.container}>
      <section className={classNames(
        isSearchOpen ? s.headerFixed : s.header, 
        (!isSearchOpen && favouritesStore.favourites.length) && s.headerMobileHidden
      )}>
        <div onClick={closeSearch} className={isSearchOpen ? s.logoWhenSearch : s.logo}>
          <Logo />
        </div>

        <div className={isSearchOpen ? s.searchSection : s.searchSectionBeforeOpen}>
          <div onClick={closeSearch} className={s.backButton}>
            <BackIcon />
          </div>
          <SearchBar 
            classForWrapper={s.searchBar}
            onEscape={closeSearch} 
          />
        </div>
        <div className={isSearchOpen ? s.equalEmptySpace : s.equalEmptySpaceBeforeOpen} />
      </section>

      <SearchDropDown className={isSearchOpen ? s.dropDownFixed : s.dropDown}/>

      {favourites.length === 0 && (
        <section className={isSearchOpen ? s.hiddenContent : s.content}>
          <div className={s.inner}>
            <Heading as='h1'>Подбери фильм мечты</Heading>
            <Paragraph>Выбирайте любимые фильмы и сериалы, добавляйте их в подборку, а мы построим рекомендации</Paragraph>
          </div>
          <div className={s.posters}>
            {/* TODO: Рефакторинг - тут должен быть просто компонент с плюсом */}
            <FavouriteList />
          </div>
        </section>
      )}
    </div>
  )
})
