import styled, { css } from 'styled-components'

interface IFontProps {
  family?: 'MTS Sans' | 'MTS Wide' | 'MTS Compact' | 'MTS Text'
  weight?: number
  align?: string
  lineHeight?: string
}

export const Heading = styled.h1<IFontProps>`
  color: #fff;
  font-family: ${p => `${p.family}, sans-serif`};
  text-align: ${p => p.align || 'inherit'}
`

Heading.defaultProps = {
  family: 'MTS Sans',
}

interface IParagraph extends IFontProps {
  size?: string;
}

export const Paragraph = styled.p<IParagraph>`
  color: #fff;
  font-family: ${p => `${p.family}, sans-serif`};
  font-size: ${p => p.size};
  line-height: ${p => p.lineHeight};
  text-align: ${p => p.align || 'inherit'};

  // TODO: Пересмотреть
  /* @include mobile {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
  } */
`

Paragraph.defaultProps = {
  family: 'MTS Compact',
  size: 'm',
}

export const Caption = styled.span<IFontProps>`
  color: #fff;
  font-family: ${p => `${p.family}, sans-serif`};
  /* font-size: 0.75rem; */
  /* line-height: 1.25rem; */
`
