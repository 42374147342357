import React, { FC, useEffect } from 'react'
import { Main } from './pages'
import { PostersBackground } from '@components'
import { gtm } from 'helpers/GTMService'
import { getClientID } from 'helpers/cookies'

export const App: FC = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (getClientID()) {
        clearInterval(intervalId)
        gtm.pushEvent({
          event: 'mayak_first_show',
          event_name: 'mayak_first_show',
        })
      }
    }, 500)
  }, [])
  
  return (
    <PostersBackground>
      <Main />
    </PostersBackground>
  )
}
