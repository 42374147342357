import { useRef, useState, FC, useEffect } from 'react'
import { AnimatePresence, motion, Transition } from 'framer-motion'
import styled from 'styled-components'
import { useResponsiveBreakpoints } from 'helpers/hooks'
import { IBreakpoints } from '../../interfaces'
import { BreakPoints, mediaQueries } from 'styles/global-styles'
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react'
import 'swiper/css';
import 'swiper/css/navigation';
import { Button } from '@components/Button'
import { Paragraph } from '@components/Typography'
import classnames from 'classnames'
import { ReactComponent as IconArrowUp } from 'assets/icon/arrow-up.svg'
import { MetaResponseApi } from 'mgw-api'
import SwiperCore, { Navigation, Keyboard } from 'swiper';
import { Loader, LoaderWrapper } from '@components/Loader'
import { observer } from 'mobx-react-lite'
import { RootStore } from 'store'

SwiperCore.use([Navigation, Keyboard])

const TRANSITION: Transition = {
  when: 'afterChildren',
  ease: 'easeOut',
}

interface ISlider {
  seed: number
  sliderRef: any
  CARD_SIZES: Map<IBreakpoints, [number, number]>
  CARD_OFFSETS: any
  elements: MetaResponseApi[]
  Poster: FC<any>
  selected?: any
  onSelect?: (idx: number) => void
  onNext: () => Promise<void>
  onAdd: () => Promise<void>
  recsLeft: boolean
}

export const Slider: FC<ISlider> = observer((props) => {
  const sliderStore = RootStore.sliderStore
  const container = useRef<HTMLDivElement>(null)
  const { breakpoint } = useResponsiveBreakpoints()

  const cardOffset = props.CARD_OFFSETS.get(breakpoint) as number

  const refSwiper = useRef<SwiperRef>(null)

  useEffect(() => {
    props.onSelect?.(sliderStore.focusedSlideIdx)
  }, [sliderStore.focusedSlideIdx])

  const [loading, setLoading] = useState(false);

  function runAction(callback: () => Promise<void>, plusCount: number) {
    setLoading(true)
    const _nextIdx = props.elements.length + plusCount;
    sliderStore.nextIdx = _nextIdx
    callback().then(() => {
      setLoading(false)
    })
  }

  async function onAdd() {
    runAction(props.onAdd, -1)  // -1 т.к. один будет удален из списка
    // Временная установка следующего выбранного фильма вместо добавленного (он удаляется из списка)
    setTimeout(() => props.onSelect?.(sliderStore.focusedSlideIdx))
  }

  const isFirstElements = props.elements.length < 9
  
  useEffect(() => {
    if (props.elements.length < sliderStore.nextIdx) return;
    if (isFirstElements) {
      const _nextIdx = props.elements.length;
      sliderStore.nextIdx = _nextIdx
      return;
    };
    refSwiper.current?.swiper.slideTo(sliderStore.nextIdx)
    props.onSelect?.(sliderStore.nextIdx)

    setPrevNextIdx(sliderStore.nextIdx)
    const _nextIdx = props.elements.length;
    sliderStore.nextIdx = _nextIdx
    
    console.log('update');
    refSwiper.current?.swiper.update()
  }, [props.elements.length])

  function onNext() {
    runAction(props.onNext, 0)
  }

  const [prevNextIdx, setPrevNextIdx] = useState(props.elements.length)

  const isFirst = props.elements.length === 8;
  function centerOffset() {
    if (isFirst) {
      if (sliderStore.focusedSlideIdx === 0) return 0;
      return 15
    }
    if (sliderStore.focusedSlideIdx === prevNextIdx) return 0;

    if (sliderStore.focusedSlideIdx <= prevNextIdx) return -15
    return 15
  }

  return (
    <>
      <Button className="ghost" style={{ justifySelf: 'center', padding: '0 13px' }} onClick={onAdd}>
        <IconArrowUp/> Добавить
      </Button>

      <Container ref={container} className="slider-container">
        <PosterList
          className='scrollable-x'
          transition={TRANSITION}
        >
          <Swiper
            ref={refSwiper}
            slidesPerView={'auto'}
            centeredSlides={true}
            slideToClickedSlide={true}
            navigation={true}
            onSlideChange={(swiper) => sliderStore.focusedSlideIdx = swiper.activeIndex}
            keyboard={true}
            style={{ 
              transform: `translate3d(${centerOffset()}px, 0px, 0px)`,
            }}
          >
              <AnimatePresence>
                {props.elements.map((el, idx) => (
                  <SwiperSlide
                    key={el.gid}
                    className={classnames(sliderStore.focusedSlideIdx === idx && 'slide-clicked', 'slide')}
                    onClick={() => sliderStore.focusedSlideIdx = idx}
                  >
                    <props.Poster
                      className="poster clickable"
                      idx={idx}
                      item={el}
                      offset={cardOffset}
                      selected={props.selected}
                    />
                  </SwiperSlide>
                ))}

                {!loading ? (
                  <SwiperSlide className='slide'>
                    <PosterMore className='poster'>
                      <Paragraph className='paragraph' family='MTS Sans' size='14px' weight={500} lineHeight="18px">
                        {props.recsLeft 
                          ? 'Получите больше рекомендаций не меняя выбор' 
                          : 'Мы не нашли ничего похожего на эти фильмы и сериалы, попробуйте изменить список'
                        }
                      </Paragraph>

                      <Button
                        onClick={onNext}
                        className="light btn"
                        disabled={loading || !props.recsLeft}
                      >
                        Ещё кино!
                      </Button>
                    </PosterMore>
                  </SwiperSlide>
                ) : (
                  <SwiperSlide className='slide'>
                      <LoaderWrapper>
                        <Loader />
                      </LoaderWrapper>
                  </SwiperSlide>
                )}
              </AnimatePresence>
          </Swiper>
        </PosterList>
      </Container>
    </>
  )
})

const Container = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
  grid-auto-columns: max-content;
  justify-content: flex-end;
  grid-gap: 1.5rem;
  max-width: inherit;

  @media screen and (max-width: ${BreakPoints.desktop}) {
    grid-template-columns: 1fr 0px !important;
    gap: 0;
  }
`

const borderRadius = '20px'
const PosterMore = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: ${borderRadius};
  border: 2px dashed white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .paragraph {
    padding: 20px 13px 0;
  }

  .btn {
    height: 35px !important;
  }
`

const hoverOffset = 16;
const PosterList = styled(motion.div)`
  max-width: 100%;
  height: calc(300px + ${hoverOffset}px);
  padding-top: ${hoverOffset}px;
  position: relative; 
  overflow: hidden;

  ${mediaQueries.mobile} {
    height: calc(220px + ${hoverOffset}px);
  }
  
  .swiper {
    overflow: visible;
    height: 100%;

    .swiper-button, .swiper-button-next {
      &-disabled {
        display: none;
      }
      
      &-prev, &-next {
        width: 32px;
        height: 32px;
        color: white;
        z-index: 9;
        border-radius: 50%;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          border: 1px solid rgba(255, 255, 255, 0.05);
        }

        &::after {
          margin-top: 3px;
          font-size: 14px;
        }
      }

      &-next {
        margin-left: 3px;
      }
    }
  }

  .slide {
    height: 240px;
    width: 160px;
    display: flex;
    transition: all 0.2s ease;
    box-sizing: content-box;
    position: relative;
    top: 0;

    ${mediaQueries.mobile} {
      height: 196px;
      width: 140px;
    }

    &:hover {
      top: -${hoverOffset}px;
    }

    .poster {
      border-radius: ${borderRadius};
    }

    &-clicked {
      height: 290px;
      width: 192px;

      ${mediaQueries.mobile} {
        height: 217px;
        width: 155px;
      }

      .poster {
        border: 2px solid white;
        overflow: hidden;
      }
    }

    &:not(.slide-clicked) {
      position: relative;

      &:hover::after {
        opacity: 0;
      }

      &::after {
        content: '';
        position: absolute;
        inset: 0;
        left: ${hoverOffset / 2}px;
        width: calc(100% - ${hoverOffset}px);
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.30) 0%, rgba(0, 0, 0, 0.30) 100%);
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.2s ease;
        border-radius: 1rem;
      }
    }
    
    padding: 0 8px;
  }

  .poster {
    width: 100%;
  }
`