import { FC, PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { mediaQueries } from 'styles/global-styles'
import poster from 'assets/poster.png'
import { motion, Variants } from 'framer-motion'
import { IPickedItems } from './SearchDropDown'
import classnames from 'classnames'

export const PosterComponent: FC<
  PropsWithChildren & IPickedItemPoster & 
  { 
    shadow?: boolean
    onClick?: () => void
    layout?: boolean
  }
> = (props) => {
  const itemVariants: Variants = {
    hidden: { 
      opacity: 0,
      maxWidth: props.layout ? undefined : 0,
    },
    visible: { 
      opacity: 1, 
      maxWidth: props.layout ? undefined : 90,
    },
  }

  return (
    <PickedItemPoster
      className={classnames(props.shadow && 'shadow-on-hover')}
      src={props.src} 
      searchOpen={props.searchOpen}
      transition={{ duration: 0.3 }}
      onClick={props.onClick}
      layout={!!props.layout}
      variants={itemVariants} 
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      {props.children}
    </PickedItemPoster>
  )
}

interface IPickedItemPoster extends IPickedItems {
  src: string
}

const PickedItemPoster = styled(motion.div)<IPickedItemPoster>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  max-width: 90px;
  flex-shrink: 0;
  height: auto;
  aspect-ratio: 90 / 126;
  border-radius: 8px;
  position: relative;
  background-size: cover;

  &:first-child {
    margin-left: ${p => p.searchOpen ? 'auto' : 'unset'};
  }
  &:last-child {
    margin-right: auto;
  }

  ${p =>
    p.src === 'placeholder' ? css`
      background-image: url(${poster});
      cursor: pointer;
    ` : css`
      background-image: url(${p.src});
    `}

  ${mediaQueries.mobile} {
    max-width: 75px;
    border-radius: 4px;
    ${p =>
      p.src === 'placeholder'
        ? css`
      background-image: url(${poster});
      cursor: pointer;
    `
        : css`
      background-image: url(${p.src});
    `}
  }
`