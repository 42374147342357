import { CollectionStore } from './collection.store';
import { FavouritesStore } from './favourites.store';
import { SearchStore } from './search.store';
import { SliderStore } from './slider.store';

export class GlobalStore {
    searchStore = new SearchStore();
    collectionStore: CollectionStore = new CollectionStore(() => this.favouritesStore);
    favouritesStore: FavouritesStore = new FavouritesStore(() => this.collectionStore);
    sliderStore: SliderStore = new SliderStore();
}

export const RootStore = new GlobalStore()