import { FC } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import { CollectionList } from './components'
import { BreakPoints } from 'styles/global-styles'
import { RootStore } from 'store'

export const CollectionSection: FC = observer(() => {
  const searchStore = RootStore.searchStore
  const collectionStore = RootStore.collectionStore
  const favouritesStore = RootStore.favouritesStore

  const visibilityCollection = ['pending', 'completed'].includes(collectionStore.fetchStatus) && favouritesStore.favourites.length
  // const visibilityCollection = true;

  const visibilityCondition = visibilityCollection ? 'visible' : 'hidden'

  const WRAPPER_TRANSITIONS = {
    visible: {
      height: '100%',
    },
    hidden: {
      height: 0,
    },
  }

  const CONTAINER_TRANSITIONS = {
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      scale: 1.1,
      y: '3rem',
    },
  }  

  return (
    <div className='container'>
      <div className='row'>
        <Container>
          <AnimatedWrapper initial={'hidden'} animate={visibilityCondition} variants={WRAPPER_TRANSITIONS} transition={{ duration: 1 }}>
            <AnimatedContainer initial={'hidden'} animate={visibilityCondition} variants={CONTAINER_TRANSITIONS}>
              {visibilityCollection && <CollectionList />}
            </AnimatedContainer>
          </AnimatedWrapper>
        </Container>
      </div>
    </div>
  )
})

const Container = styled.div`
  display: grid;
  margin: 0 auto;
`

const AnimatedWrapper = styled(motion.div)`
  width: 100%;
`

const AnimatedContainer = styled(motion.div)`
  background: var(--color-black-100);
  border-radius: 0 0 50px 50px;
  padding-top: 24px;
  padding-bottom: 48px;
  box-shadow: 0px -20px 20px 0px #FFFFFF40;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    box-shadow: 0px 0px 20px 0px #FFFFFF40;
    width: 100%;
    height: 50px;
    z-index: -1;
    bottom: 0;
    border-radius: 0px 0px 50px 50px;
  }

  @media screen and (max-width: ${BreakPoints.tablet}) {
    border-radius: 0;
  }
`
