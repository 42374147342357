import { FC, useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { observer } from 'mobx-react-lite'
import { RootStore } from 'store'
import { ShelfItemApi } from 'mgw-api'
import { Button } from '@components/Button'
import { SearchItem } from './components/SearchItem'
import { gap, mediaQueries } from 'styles/global-styles'
import { ReactComponent as PlusSmallest } from 'assets/icon/plus-smallest.svg'
import { ReactComponent as Cross } from 'assets/icon/cross-new.svg'
import { LoaderWrapper, Loader } from '@components/Loader'
import { AnimatePresence } from 'framer-motion'
import { PosterComponent } from './PosterItem'
import { gtm } from 'helpers/GTMService'
import React from 'react'

interface ISearchDropDown {
  className?: string
}

export const SearchDropDown: FC<ISearchDropDown> = observer(({ className }) => {
  const storeSearch = RootStore.searchStore
  const favouritesStore = RootStore.favouritesStore
  const collectionStore = RootStore.collectionStore  

  const { addToFavourites, removeFromFavourites, favourites } = favouritesStore
  const isMobile = window.innerWidth < 768

  // Нужно для того, чтобы в ResultsWrapper не появлялся скроллбар при открытии дропдауна
  const [overflowAutoForResultsWrapper, setOverflowAutoForResultsWrapper] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      setOverflowAutoForResultsWrapper(storeSearch.isSearchOpen)
    }, 500)
  }, [storeSearch.isSearchOpen])

  function searchRecs() {
    collectionStore.fetchPositiveFavourites(favouritesStore.positive, 8, 1, false, true);  
    storeSearch.closeSearch();
  }

  function onBtnClick() {
    searchRecs();
    gtm.pushEvent({
      event: 'mayak_button_click', 
      event_name: 'mayak_button_click', 
      button_id: '1',
      button_text: 'Перейти к рекомендациям',
    })
  }

  function onPick(item: ShelfItemApi) {
    addToFavourites(item)
  }

  function onDiscard(gid: string) {
    removeFromFavourites(gid)
    if (!storeSearch.isSearchOpen) searchRecs()
  }

  const skeletonList = Array.from(Array(6-favourites.length >= 1 ? 6-favourites.length : !isMobile ? 0 : 1).keys());

  const slicedArr = favourites.slice(0, 5);

  return (
      <DropDown 
        className={className}
        open={storeSearch.isSearchOpen}
        heightWhenClosed={!favourites.length ? 0 : 218} // Высота = выстота блока PickedItemsWrapper - (высота ConfirmButton + маргин боттом)
        heightWhenClosedForMobile={!favourites.length ? 0 : 120} // TODO: Автоматически отслеживать высоту
      >
        {(storeSearch.loading && !storeSearch.searchResult.length) && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}

        {/* Результаты поиска */}
        {(storeSearch.query && storeSearch.searchResult.length > 0) && (
          <ResultsWrapper 
            searchOpen={storeSearch.isSearchOpen}
            setOverflowAuto={overflowAutoForResultsWrapper}
          >
            <Heading>Результаты поиска</Heading>
            <Results>
              {storeSearch.searchResult.map(el => (
                <SearchItem 
                  key={el.gid}
                  item={el}
                  picked={favourites.some(item => item.gid === el.gid)}
                  onPick={() => onPick(el)}
                  onDiscard={() => onDiscard(el.gid)}
                />
              ))}
            </Results>
          </ResultsWrapper>
        )}

        {(storeSearch.query && !storeSearch.searchResult.length && !storeSearch.loading) && (
          <SearchWarning>{`По запросу «${storeSearch.query}» ничего не найдено`}</SearchWarning>
        )}

        {/* Популярные запросы */}
        {!storeSearch.loading && (!storeSearch.query || !storeSearch.searchResult.length) && (
          <ResultsWrapper 
            searchOpen={storeSearch.isSearchOpen}
            setOverflowAuto={overflowAutoForResultsWrapper}
          >
            <Heading>Популярные запросы</Heading>
            <Results>
              {storeSearch.popular.map(el => (
                <SearchItem 
                  key={el.gid}
                  item={el}
                  picked={favourites.some(item => item.gid === el.gid)}
                  onPick={() => onPick(el)}
                  onDiscard={() => onDiscard(el.gid)}
                />
              ))}
            </Results>
          </ResultsWrapper>
        )}
        
        {/* TODO: Если нужно будет набирать фильмы с нуля, то создать в searchStore поле pickedItems и заполнять его, и там же добавлять в favourites */}
        <PickedItemsWrapper open={!!favourites.length}>
          <Heading isSearchOpen={storeSearch.isSearchOpen} style={{paddingLeft: 0}}>Вы выбрали</Heading>

          <PickedItems searchOpen={storeSearch.isSearchOpen}>
            <AnimatePresence>
              {/* Карточка добавления фильма "Плюс" */}
              {!storeSearch.isSearchOpen && (
                <PosterComponent        
                  shadow={true} 
                  src={'placeholder'}
                  onClick={storeSearch.openSearch}
                  searchOpen={storeSearch.isSearchOpen}
                >
                  <PlusSmallest/>
                </PosterComponent>
              )}

              {/* Выбранные фильмы на десктопной версии */}
              {!isMobile ? (
                <AnimatePresence>
                  {favourites.map(el => (
                    <PosterComponent 
                      key={el.gid} 
                      shadow={true} 
                      src={el.imageUrl}
                      searchOpen={storeSearch.isSearchOpen}
                      layout={false}
                    >
                      <CrossWrapper onClick={() => onDiscard(el.gid)}>
                        <Cross />
                      </CrossWrapper>
                    </PosterComponent>
                  ))}
                </AnimatePresence>
              ) : (
                <React.Fragment key={'slicedArr'}>
                  {slicedArr.map(el => (
                    // React.fragment - Обязателен!
                    // Выбранные фильмы в мобильной версии
                    <PosterComponent 
                      key={el.gid} 
                      shadow={true} 
                      src={el.imageUrl} 
                      searchOpen={storeSearch.isSearchOpen}
                      layout={true}
                    >
                      <CrossWrapper onClick={() => onDiscard(el.gid)}>
                        <Cross />
                      </CrossWrapper>
                    </PosterComponent>
                  ))}
                </React.Fragment>
              )}

              {/* Скелетоны под фильмы */}
              {isMobile ? storeSearch.isSearchOpen ? (skeletonList.map((_, idx) => (      
                  <PosterComponent 
                    key={idx}
                    src={"placeholder"} 
                    searchOpen={storeSearch.isSearchOpen}
                    layout={true}
                  >
                    {/* +N фильмов */}
                    {6-favourites.length <= 0 && (
                      '+' + (favourites.length - 5)
                    )}
                  </PosterComponent>
              ))) : (favourites.length > 5 && (
                  // +N фильмов скелетон при свернутом меню поиска
                  <PosterComponent 
                    src={"placeholder"} 
                    searchOpen={storeSearch.isSearchOpen}
                  >
                    +{favourites.length - 5}
                  </PosterComponent>
              )) : (
                <AnimatePresence>
                  {skeletonList.map((_, idx) => (      
                    <PosterComponent 
                      key={idx}
                      src={"placeholder"} 
                      searchOpen={storeSearch.isSearchOpen}
                      layout={false}
                    />
                  ))}
                </AnimatePresence>
              )}
            </AnimatePresence>
          </PickedItems>

          <ConfirmButtonWrapper searchOpen={storeSearch.isSearchOpen}>
            <ConfirmButton onClick={onBtnClick}>Перейти к рекомендациям</ConfirmButton>
          </ConfirmButtonWrapper>
        </PickedItemsWrapper>
      </DropDown>
  )
})


const Scroll = styled.div`
  scrollbar-color: var(--color-secondary-white) transparent;
  scrollbar-width: thin;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 8px;
    height: 4px;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary-white);
    border-radius: 4px 8px 8px 4px;
    border-right: 4px transparent solid;
    background-clip: padding-box;
  }
`

const VerticalScroll = styled(Scroll)`
  @supports not (overflow-y: overlay) {
    overflow-y: auto;
  }

  @supports (overflow-y: overlay) {
    overflow-y: overlay;
  }
`

const HorizontalScroll = styled(Scroll)`
  overflow-x: scroll;
  padding-bottom: 4px;

  ${mediaQueries.tablet} {
    padding-bottom: 0;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      height: 0;
    }
  }
`

interface IDropDown {
  open: boolean
  heightWhenClosed: number
  heightWhenClosedForMobile: number
}

const DropDown = styled.div<IDropDown>`
  transition: ${p => p.open ? 
    'height 1s ease, opacity 0.5s ease-out' : 
    `height 1s ease, opacity 0.9s ease-in`
  };
  height: ${p => p.open ? '844px' : `${p.heightWhenClosed}px`};
  opacity: ${p => p.open || p.heightWhenClosed > 0 ? 1 : 0};
  background: var(--color-background);
  width: 768px;
  box-shadow: 0px -20px 20px 0px #FFFFFF40;
  color: var(--color-white);

  position: relative;
  display: flex;
  flex-direction: column;

  overflow: hidden;

  ${mediaQueries.tablet} {
    height: ${p => p.open ? '844px' : `${p.heightWhenClosed}px`};
    border-radius: 0;
    box-shadow: none;
  }

  ${mediaQueries.mobile} {
    height: ${p => p.open ? '100%' : `${p.heightWhenClosedForMobile}px`};
  }
`

const fadeInOutHeight = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 28px;
  }
`
interface IHeading {
  isSearchOpen?: boolean
}

const Heading = styled.div<IHeading>`
  font-family: 'MTS Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  padding: ${gap.common} calc(${gap.common});
  animation: ${fadeInOutHeight} 0.5s ease;

  ${mediaQueries.mobile} {
    display: ${p => p.isSearchOpen ? 'none' : 'block'};
    padding: ${p => p.isSearchOpen ? `${gap.mobile} 0 0 0` : `${gap.mobile} calc(${gap.mobile})`};
    font-size: 17px;
    line-height: 24px;
  }
`

interface IResultsWrapper {
  searchOpen: boolean
  setOverflowAuto: boolean
}

const ResultsWrapper = styled.div<IResultsWrapper>`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: ${p => p.setOverflowAuto ? 'auto' : 'hidden'};

  ${mediaQueries.tablet} {
    padding: 0 ${gap.mobile};
  }
`

const Results = styled(VerticalScroll)`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 ${gap.common};
  gap: ${gap.common};

  ${mediaQueries.mobile} {
    gap: ${gap.mobile};
    padding: 0 ${gap.mobile};
  }
`

const SearchWarning = styled.div`
  font-family: 'MTS Text';
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  padding: ${gap.common} ${gap.common} 0 ${gap.common};
  
  ${mediaQueries.mobile} {
    padding: ${gap.mobile} ${gap.mobile} 0 ${gap.mobile};
  }
`

interface IPickedItemsWrapper {
  open: boolean
}

const PickedItemsWrapper = styled.div<IPickedItemsWrapper>`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: ${p => p.open ? 
    'max-height 0.5s ease-out' : 
    'max-height 0.2s ease'
  };
  background: var(--color-secondary-background);
  height: fit-content;
  max-height: ${p => p.open ? '400px' : '0'};
  position: relative;
  padding: 0 ${gap.common};

  ${mediaQueries.mobile} {
    padding: 0 ${gap.mobile};
  }
`

export interface IPickedItems {
  searchOpen: boolean
}

const PickedItems = styled(HorizontalScroll)<IPickedItems>`
  transition: height 0.5s ease;
  transition-delay: 0.2s;
  max-width: 100%;
  height: ${p => p.searchOpen ? '98px' : '126px'};
  display: flex;
  gap: ${`calc(${gap.common} / 2)`};
  margin-bottom: ${gap.common};
  width: 100%;

  /* justify-items: ${p => p.searchOpen ? 'safe center' : 'flex-start'}; // TODO: Сделать чтобы блок плавно уезжал влево после закрытия поиска */

  ${mediaQueries.mobile} {
    width: ${p => p.searchOpen ? 'calc(100% - 48px)' : '100%'};
    height: ${p => p.searchOpen ? '56px' : '105px'};
    gap: ${`calc(${gap.mobile} / 2)`};
    margin: ${p => p.searchOpen ? `${gap.mobile} auto` : `0 0 ${gap.mobile} 0`};

    > * {
      width: 40px;
      height: 56px;
    }
  }
`

const CrossWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 1;
`

interface IConfirmButtonWrapper {
  searchOpen: boolean
}

const ConfirmButtonWrapper = styled(Button)<IConfirmButtonWrapper>`
  align-self: center;
  transition: opacity 0.3s ease-in, height 0.5s ease, padding-bottom 0.4s ease;
  transition-delay: 0.2s, 0, 0;
  padding-bottom: ${p => p.searchOpen ? gap.common : '0'};
  height: ${p => p.searchOpen ? `calc(44px + ${gap.common})` : '0'};
  max-width: 100%;
  opacity: ${p => p.searchOpen ? 1 : 0};
  pointer-events: ${p => p.searchOpen ? 'auto' : 'none'};

  ${mediaQueries.mobile} {
    padding-bottom: ${p => p.searchOpen ? gap.mobile : '0'};
    height: ${p => p.searchOpen ? `calc(44px + ${gap.mobile})` : '0'};
    width: 100%;
  }
`

const ConfirmButton = styled(Button)`
  height: 44px;
  width: inherit;
  background: var(--color-gold-hover);
  /* transition: background 0.05 ease; */

  font-family: 'MTS Compact';
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: var(--color-black-100);

  /* &:hover {
    background: var(--color-gold-hover);
  } */

  ${mediaQueries.tablet} {
    width: 100%;
  }
`
