import { getClientID } from "./cookies"
import {v4 as uuidv4} from "uuid"

export type DLEvent = 'mayak_first_show' | 'mayak_show' | "mayak_search" | "mayak_button_click"

export type DLEventProfile = 'mts_guest' | string

export interface DataLayer {
    event?: DLEvent | null
    event_name?: DLEvent | null
    screen?: string | null
    userId?: string | null
    client_id?: string | null // берется из яндекс метрики
    event_datetime?: string | null
    platform?: string | null // web30_landing_mayak
    huawei_subscriber_id?: string | null // 'mts_guest',

    term?: string | null //"текст поиска"
    search_films?: string[] | null // <массив ид фильмов, по которым ИЩУТ>
    recommended_films?: string[] | null // <массив ид фильмов, которые РЕКОМЕНДУЕМ>,
    button_id?: string | null
    button_text?: string | null

    session_id?: string
}

declare const dataLayer: DataLayer[]

export class GTMService {
    public sessionId = uuidv4()

    public pushEvent(_dl: DataLayer = {}) {
        const data = {
            ...this._getCommonData(),
            ..._dl
        }

        if (process.env.NODE_ENV === 'production') {
            console.info('data pushed')
            dataLayer.push(data)
        } else {
            console.info('[GTM] Data pushed to dataLayer', data)
        }
    }

    private _getCommonData(): DataLayer {
        return {
            event: 'mayak_show',
            event_name: 'mayak_show',
            screen: '/mayak_landing',
            client_id: getClientID(),
            userId: null,
            event_datetime: new Date().toISOString(),
            huawei_subscriber_id: 'mts_guest',
            platform: 'web30_landing_mayak',
            session_id: this.sessionId,
        }
    }
}

export const gtm = new GTMService()