import { FC } from 'react'
import { Caption } from '@components'
import s from './footer.module.scss'
import styled from 'styled-components'

export const Footer: FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className={s['container']}>
      <Caption 
        family='MTS Compact'
        weight={500}
        children={`© ${currentYear} ПАО «МТС». Все права защищены.`} 
      />

      <Subscription href="https://cms.developers.mts.ru/api/uploads/articles/files/6526543d0dcb65364ae2e15c.docx">
        Правила рекомендательных технологий
      </Subscription>
    </footer>
  )
}

const Subscription = styled.a`
  color: var(--web-secondary-gray, #849AB2);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* Caption/C1 Regular Comp */
  font-family: MTS Compact;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */

  text-decoration: none;
`